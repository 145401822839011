import React, { useState, useEffect } from 'react';
import Home from './Home';
import _ from 'lodash';
import firebaseDb from '../firebase';
import { useParams, Link } from 'react-router-dom';

const NewMatchBoardForm = () => {
  const initialFieldValues = {
    values: {},
  };
  const _initialFieldValues = {
    _values: {},
  };
  const __initialFieldValues = {
    __values: {},
  };
  const ___initialFieldValues = {
    ___values: {},
  };
  var [values, setValues] = useState(initialFieldValues);
  var [_values, set_Values] = useState(_initialFieldValues);
  var [__values, set__Values] = useState(__initialFieldValues);
  var [___values, set___Values] = useState(___initialFieldValues);
  let { matchId, playerId } = useParams();
  var [playerObjects, setPlayerObjects] = useState({});
  var [matchObjects, setMatchObject] = useState({});
  var [scoreCardObjects, setScoreCardObject] = useState({});
  useEffect(() => {
    firebaseDb.child('players').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setPlayerObjects({ ...snapshot.val() });
      } else {
        setPlayerObjects({});
      }
    });
    firebaseDb.child('matches').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setMatchObject({ ...snapshot.val() });
      } else {
        setMatchObject({});
      }
    });
    firebaseDb.child('scorecard').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setScoreCardObject({ ...snapshot.val() });
      } else {
        setScoreCardObject({});
      }
    });
  }, []);
  useEffect(() => {
    let match = matchObjects[matchId];
    if (match) {
      //let player1 = match[`player${playerId}`];
      let player1 = match[`player1`];
      let otherId = parseInt(playerId) === 1 ? 2 : 1;
      //let player2 = match[`player${otherId}`];
      let player2 = match[`player2`];
      let player3, player4;
      if(parseInt(match.playersCount) === 4){
        player3 = match[`player3`];
        player4 = match[`player4`];
      }
      Object.keys(scoreCardObjects).map((id) => {
        if (scoreCardObjects[id].matchId === matchId) {
          if (scoreCardObjects[id].playerId === player1) {
            setValues(scoreCardObjects[id].score || []);
          }
          if (scoreCardObjects[id].playerId === player2) {
            set_Values(scoreCardObjects[id].score || []);
          }
          if(parseInt(match.playersCount) === 4){
            if (scoreCardObjects[id].playerId === player3) {
              set__Values(scoreCardObjects[id].score || []);
            }
            if (scoreCardObjects[id].playerId === player4) {
              set___Values(scoreCardObjects[id].score || []);
            }
          }
        }
      });
    }
  }, [scoreCardObjects]);
  let match, player1, player2,player3, player4;
  if (matchObjects) {
    match = matchObjects[matchId];
  }
  if (match) {
    //player1 = match[`player${playerId}`];
    player1 = match[`player1`];
    let otherId = parseInt(playerId) === 1 ? 2 : 1;
    //player2 = match[`player${otherId}`];
    player2 = match[`player2`];
    if(parseInt(match.playersCount) === 4){
      player3 = match[`player3`];
      player4 = match[`player4`];
    }
  }
  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const _handleInputChange = (e) => {
    var { name, value } = e.target;
    set_Values({
      ..._values,
      [name]: value,
    });
  };
  const __handleInputChange = (e) => {
    var { name, value } = e.target;
    set__Values({
      ...__values,
      [name]: value,
    });
  };
  const ___handleInputChange = (e) => {
    var { name, value } = e.target;
    set___Values({
      ...___values,
      [name]: value,
    });
  };
  const updateMatchWinner = (player) => {
    let obj = { ...matchObjects[matchId], winner: player };
    firebaseDb.child(`matches/${matchId}`).set(obj, (err) => {
      if (err) {
        console.log(err);
      }
    });
  };
  const updateMatchWinner4 = (player1,player2) => {
    let obj = { ...matchObjects[matchId], winner1: player1,winner2: player2 };
    firebaseDb.child(`matches/${matchId}`).set(obj, (err) => {
      if (err) {
        console.log(err);
      }
    });
  };
  const findMatchWinner2 = (value1, value2, p1, p2) => {
    let mappedValues = _.map(value2, (val) => {
      return parseInt(val);
    });
    let _mappedValues = [];
    Object.keys(value1).map((id) => {
      _mappedValues.push(parseInt(value1[id]));
    });
    if (_.max(mappedValues) === _.max(_mappedValues)) {
      alert('The highest scores are equal');
    } else if (_.max(mappedValues) > _.max(_mappedValues)) {
      if (_.max(mappedValues) !== undefined) {
        updateMatchWinner(p2);
      } else {
        updateMatchWinner(p1);
      }
    } else {
      if (_.max(_mappedValues) !== undefined) {
        updateMatchWinner(p1);
      } else {
        updateMatchWinner(p2);
      }
    }
  };
  const findMatchWinner4 = (value1, value2, value3, value4, p1, p2, p3, p4) => {
    let playerValueMap = [
      {id: p1, value: _.max(_.map(value1, (val) => { return parseInt(val);}))},
      {id: p2, value: _.max(_.map(value2, (val) => { return parseInt(val);}))},
      {id: p3, value: _.max(_.map(value3, (val) => { return parseInt(val);}))},
      {id: p4, value: _.max(_.map(value4, (val) => { return parseInt(val);}))},
    ];
    let sortedArray = _.sortBy(playerValueMap, ['value']);
    console.log(sortedArray[3].id);
    updateMatchWinner4(sortedArray[3]?.id,sortedArray[2]?.id);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isFinalStarted() && isQualifierMatch()) {
      alert("Final match started; can't update the Qualifier match score!");
      return false;
    }
    let obj = {};
    let update = false;
    let currentId = '';
    Object.keys(scoreCardObjects).map((id) => {
      if (scoreCardObjects[id].matchId === matchId) {
        if (scoreCardObjects[id].playerId === player1) {
          update = true;
          currentId = id;
        }
      }
    });
    if (update) {
      obj = { ...scoreCardObjects[currentId], score: values };
    } else {
      obj = {
        matchId: matchId,
        playerId: player1,
        score: values,
      };
    }
    if (update) {
      firebaseDb.child(`scorecard/${currentId}`).set(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    } else {
      firebaseDb.child('scorecard').push(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    }
    if(parseInt(match.playersCount) === 4){
      if (_.size(values) === 6 && _.size(_values) === 6 && _.size(__values) === 6 && _.size(___values) === 6) {
        findMatchWinner4(values, _values, __values, ___values, player1, player2, player3, player4);
      }
    } else if(_.size(values) === 6 && _.size(_values) === 6) {
      findMatchWinner2(values, _values, player1, player2);
    }
  };
  const _handleFormSubmit = (e) => {
    e.preventDefault();
    if (isFinalStarted() && isQualifierMatch()) {
      alert("Final match started; can't update the Qualifier match score!");
      return false;
    }
    let obj = {};
    let update = false;
    let currentId = '';
    Object.keys(scoreCardObjects).map((id) => {
      if (scoreCardObjects[id].matchId === matchId) {
        if (scoreCardObjects[id].playerId === player2) {
          update = true;
          currentId = id;
        }
      }
    });
    if (update) {
      obj = { ...scoreCardObjects[currentId], score: _values };
    } else {
      obj = {
        matchId: matchId,
        playerId: player2,
        score: _values,
      };
    }
    if (update) {
      firebaseDb.child(`scorecard/${currentId}`).set(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    } else {
      firebaseDb.child('scorecard').push(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    }
    if(parseInt(match.playersCount) === 4){
      if (_.size(values) === 6 && _.size(_values) === 6 && _.size(__values) === 6 && _.size(___values) === 6) {
        findMatchWinner4(values, _values, __values, ___values, player1, player2, player3, player4);
      }
    } else if(_.size(values) === 6 && _.size(_values) === 6) {
      findMatchWinner2(values, _values, player1, player2);
    }
  };
  const __handleFormSubmit = (e) => {
    e.preventDefault();
    if (isFinalStarted() && isQualifierMatch()) {
      alert("Final match started; can't update the Qualifier match score!");
      return false;
    }
    let obj = {};
    let update = false;
    let currentId = '';
    Object.keys(scoreCardObjects).map((id) => {
      if (scoreCardObjects[id].matchId === matchId) {
        if (scoreCardObjects[id].playerId === player3) {
          update = true;
          currentId = id;
        }
      }
    });
    if (update) {
      obj = { ...scoreCardObjects[currentId], score: __values };
    } else {
      obj = {
        matchId: matchId,
        playerId: player3,
        score: __values,
      };
    }
    if (update) {
      firebaseDb.child(`scorecard/${currentId}`).set(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    } else {
      firebaseDb.child('scorecard').push(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    }
    if(parseInt(match.playersCount) === 4){
      if (_.size(values) === 6 && _.size(_values) === 6 && _.size(__values) === 6 && _.size(___values) === 6) {
        findMatchWinner4(values, _values, __values, ___values, player1, player2, player3, player4);
      }
    } else if(_.size(values) === 6 && _.size(_values) === 6) {
      findMatchWinner2(values, _values, player1, player2);
    }
  };
  const ___handleFormSubmit = (e) => {
    e.preventDefault();
    if (isFinalStarted() && isQualifierMatch()) {
      alert("Final match started; can't update the Qualifier match score!");
      return false;
    }
    let obj = {};
    let update = false;
    let currentId = '';
    Object.keys(scoreCardObjects).map((id) => {
      if (scoreCardObjects[id].matchId === matchId) {
        if (scoreCardObjects[id].playerId === player4) {
          update = true;
          currentId = id;
        }
      }
    });
    if (update) {
      obj = { ...scoreCardObjects[currentId], score: ___values };
    } else {
      obj = {
        matchId: matchId,
        playerId: player4,
        score: ___values,
      };
    }
    if (update) {
      firebaseDb.child(`scorecard/${currentId}`).set(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    } else {
      firebaseDb.child('scorecard').push(obj, (err) => {
        if (err) {
          console.log(err);
        }
      });
    }
    if(parseInt(match.playersCount) === 4){
      if (_.size(values) === 6 && _.size(_values) === 6 && _.size(__values) === 6 && _.size(___values) === 6) {
        findMatchWinner4(values, _values, __values, ___values, player1, player2, player3, player4);
      }
    } else if(_.size(values) === 6 && _.size(_values) === 6) {
      findMatchWinner2(values, _values, player1, player2);
    }
  };
  const isQualifierMatch = () => {
    let currentMatch = matchObjects[matchId];
    if (_.isEqual(currentMatch.matchType, 'Qualifier')) {
      return true;
    }
    return false;
  };
  const isFinalMatch = () => {
    let currentMatch = matchObjects[matchId];
    if (_.isEqual(currentMatch.matchType, 'Final')) {
      return true;
    }
    return false;
  };
  const isFinalStarted = () => {
    let finalKey = _.last(_.keys(matchObjects));
    let finalMatch = _.head(_.filter(matchObjects, { matchType: 'Final' }));
    let firstPlayer, secondPlayer, player1Scorecard, player2Scorecard;
    if (finalMatch) {
      firstPlayer = finalMatch.player1;
      secondPlayer = finalMatch.player2;
      player1Scorecard = _.filter(scoreCardObjects, {
        matchId: finalKey,
        playerId: firstPlayer,
      });
      player2Scorecard = _.filter(scoreCardObjects, {
        matchId: finalKey,
        playerId: secondPlayer,
      });
    }
    if (
      _.size(finalMatch) &&
      (_.size(player1Scorecard) || _.size(player2Scorecard))
    ) {
      return true;
    }
    return false;
  };
  return (
    <Home>
      <div className="card-holder">
        <div className="player-card">
          <div className="player-card-header">
            <div className="player-card-header-left">
              {/* <div className="player-card-header-image">
                {player1 && playerObjects[player1].playerNumber}
              </div> */}
              <div className="player-card-header-name">
              <div>{player1 && playerObjects[player1].firstName}</div>
              <div>{player1 && playerObjects[player1].lastName}</div>
              </div>
              <div className="player-card-header-score">{_.max(_.map(values, _.parseInt))}</div>
            </div>
            {/* <div className="player-card-header-right">
              <div className="player-card-header-right-label">Money bet</div>
              <div className="player-card-header-right-value">
                <form autoComplete="off" onSubmit={handleBetSubmit}>
                  <input
                    className="bet-input"
                    placeholder=""
                    name="bet"
                    value={bet}
                    onChange={handleBetInputChange}
                    onBlur={handleBetSubmit}
                  />
                </form>
              </div>
            </div> */}
          </div>
          <div className="player-card-score">
            <div className="player-card-score-item">
              {/* <span>1.</span> */}
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <input
                  className={
                    values[0] >= _.max(_.map(values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="0"
                  value={values[0]}
                  onChange={handleInputChange}
                  onBlur={handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>2.</span> */}
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <input
                  className={
                    values[1] >= _.max(_.map(values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="1"
                  value={values[1]}
                  onChange={handleInputChange}
                  onBlur={handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>3.</span> */}
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <input
                  className={
                    values[2] >= _.max(_.map(values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="2"
                  value={values[2]}
                  onChange={handleInputChange}
                  onBlur={handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>4.</span> */}
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <input
                  className={
                    values[3] >= _.max(_.map(values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="3"
                  value={values[3]}
                  onChange={handleInputChange}
                  onBlur={handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>5.</span> */}
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <input
                  className={
                    values[4] >= _.max(_.map(values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="4"
                  value={values[4]}
                  onChange={handleInputChange}
                  onBlur={handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>6.</span> */}
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <input
                  className={
                    values[5] >= _.max(_.map(values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="5"
                  value={values[5]}
                  onChange={handleInputChange}
                  onBlur={handleFormSubmit}
                />
              </form>
            </div>
          </div>
        </div>
        <div className="player-card">
          <div className="player-card-header">
            <div className="player-card-header-left">
              {/* <div className="player-card-header-image">
                {player1 && playerObjects[player2].playerNumber}
              </div> */}
              <div className="player-card-header-name">
                <div>{player1 && playerObjects[player2].firstName}</div>
                <div>{player1 && playerObjects[player2].lastName}</div>
              </div>
              <div className="player-card-header-score">{_.max(_.map(_values, _.parseInt))}</div>
            </div>
            {/* <div className="player-card-header-right">
              <div className="player-card-header-right-label">Money bet</div>
              <div className="player-card-header-right-value">
                <form autoComplete="off" onSubmit={_handleBetSubmit}>
                  <input
                    className="bet-input"
                    placeholder=""
                    name="_bet"
                    value={_bet}
                    onChange={_handleBetInputChange}
                    onBlur={_handleBetSubmit}
                  />
                </form>
              </div>
            </div> */}
          </div>
          <div className="player-card-score">
            <div className="player-card-score-item">
              {/* <span>1.</span> */}
              <form autoComplete="off" onSubmit={_handleFormSubmit}>
                <input
                  className={
                    _values[0] >= _.max(_.map(_values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="0"
                  value={_values[0]}
                  onChange={_handleInputChange}
                  onBlur={_handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>2.</span> */}
              <form autoComplete="off" onSubmit={_handleFormSubmit}>
                <input
                  className={
                    _values[1] >= _.max(_.map(_values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="1"
                  value={_values[1]}
                  onChange={_handleInputChange}
                  onBlur={_handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>3.</span> */}
              <form autoComplete="off" onSubmit={_handleFormSubmit}>
                <input
                  className={
                    _values[2] >= _.max(_.map(_values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="2"
                  value={_values[2]}
                  onChange={_handleInputChange}
                  onBlur={_handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>4.</span> */}
              <form autoComplete="off" onSubmit={_handleFormSubmit}>
                <input
                  className={
                    _values[3] >= _.max(_.map(_values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="3"
                  value={_values[3]}
                  onChange={_handleInputChange}
                  onBlur={_handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>5.</span> */}
              <form autoComplete="off" onSubmit={_handleFormSubmit}>
                <input
                  className={
                    _values[4] >= _.max(_.map(_values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="4"
                  value={_values[4]}
                  onChange={_handleInputChange}
                  onBlur={_handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>6.</span> */}
              <form autoComplete="off" onSubmit={_handleFormSubmit}>
                <input
                  className={
                    _values[5] >= _.max(_.map(_values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="5"
                  value={_values[5]}
                  onChange={_handleInputChange}
                  onBlur={_handleFormSubmit}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      {
        parseInt(match?.playersCount) === 4 && (
          <div className="card-holder">
        <div className="player-card">
          <div className="player-card-header">
            <div className="player-card-header-left">
              {/* <div className="player-card-header-image">
                {player1 && playerObjects[player1].playerNumber}
              </div> */}
              <div className="player-card-header-name">
              <div>{player3 && playerObjects[player3].firstName}</div>
              <div>{player3 && playerObjects[player3].lastName}</div>
              </div>
              <div className="player-card-header-score">{_.max(_.map(__values, _.parseInt))}</div>
            </div>
            {/* <div className="player-card-header-right">
              <div className="player-card-header-right-label">Money bet</div>
              <div className="player-card-header-right-value">
                <form autoComplete="off" onSubmit={handleBetSubmit}>
                  <input
                    className="bet-input"
                    placeholder=""
                    name="bet"
                    value={bet}
                    onChange={handleBetInputChange}
                    onBlur={handleBetSubmit}
                  />
                </form>
              </div>
            </div> */}
          </div>
          <div className="player-card-score">
            <div className="player-card-score-item">
              {/* <span>1.</span> */}
              <form autoComplete="off" onSubmit={__handleFormSubmit}>
                <input
                  className={
                    __values[0] >= _.max(_.map(__values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="0"
                  value={__values[0]}
                  onChange={__handleInputChange}
                  onBlur={__handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>2.</span> */}
              <form autoComplete="off" onSubmit={__handleFormSubmit}>
                <input
                  className={
                    __values[1] >= _.max(_.map(__values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="1"
                  value={__values[1]}
                  onChange={__handleInputChange}
                  onBlur={__handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>3.</span> */}
              <form autoComplete="off" onSubmit={__handleFormSubmit}>
                <input
                  className={
                    __values[2] >= _.max(_.map(__values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="2"
                  value={__values[2]}
                  onChange={__handleInputChange}
                  onBlur={__handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>4.</span> */}
              <form autoComplete="off" onSubmit={__handleFormSubmit}>
                <input
                  className={
                    __values[3] >= _.max(_.map(__values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="3"
                  value={__values[3]}
                  onChange={__handleInputChange}
                  onBlur={__handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>5.</span> */}
              <form autoComplete="off" onSubmit={__handleFormSubmit}>
                <input
                  className={
                    __values[4] >= _.max(_.map(__values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="4"
                  value={__values[4]}
                  onChange={__handleInputChange}
                  onBlur={__handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>6.</span> */}
              <form autoComplete="off" onSubmit={__handleFormSubmit}>
                <input
                  className={
                    __values[5] >= _.max(_.map(__values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="5"
                  value={__values[5]}
                  onChange={__handleInputChange}
                  onBlur={__handleFormSubmit}
                />
              </form>
            </div>
          </div>
        </div>
        <div className="player-card">
          <div className="player-card-header">
            <div className="player-card-header-left">
              {/* <div className="player-card-header-image">
                {player1 && playerObjects[player2].playerNumber}
              </div> */}
              <div className="player-card-header-name">
                <div>{player4 && playerObjects[player4].firstName}</div>
                <div>{player4 && playerObjects[player4].lastName}</div>
              </div>
              <div className="player-card-header-score">{_.max(_.map(___values, _.parseInt))}</div>
            </div>
            {/* <div className="player-card-header-right">
              <div className="player-card-header-right-label">Money bet</div>
              <div className="player-card-header-right-value">
                <form autoComplete="off" onSubmit={_handleBetSubmit}>
                  <input
                    className="bet-input"
                    placeholder=""
                    name="_bet"
                    value={_bet}
                    onChange={_handleBetInputChange}
                    onBlur={_handleBetSubmit}
                  />
                </form>
              </div>
            </div> */}
          </div>
          <div className="player-card-score">
            <div className="player-card-score-item">
              {/* <span>1.</span> */}
              <form autoComplete="off" onSubmit={___handleFormSubmit}>
                <input
                  className={
                    ___values[0] >= _.max(_.map(___values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="0"
                  value={___values[0]}
                  onChange={___handleInputChange}
                  onBlur={___handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>2.</span> */}
              <form autoComplete="off" onSubmit={___handleFormSubmit}>
                <input
                  className={
                    ___values[1] >= _.max(_.map(___values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="1"
                  value={___values[1]}
                  onChange={___handleInputChange}
                  onBlur={___handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>3.</span> */}
              <form autoComplete="off" onSubmit={___handleFormSubmit}>
                <input
                  className={
                    ___values[2] >= _.max(_.map(___values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="2"
                  value={___values[2]}
                  onChange={___handleInputChange}
                  onBlur={___handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>4.</span> */}
              <form autoComplete="off" onSubmit={___handleFormSubmit}>
                <input
                  className={
                    ___values[3] >= _.max(_.map(___values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="3"
                  value={___values[3]}
                  onChange={___handleInputChange}
                  onBlur={___handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>5.</span> */}
              <form autoComplete="off" onSubmit={___handleFormSubmit}>
                <input
                  className={
                    ___values[4] >= _.max(_.map(___values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="4"
                  value={___values[4]}
                  onChange={___handleInputChange}
                  onBlur={___handleFormSubmit}
                />
              </form>
            </div>
            <div className="player-card-score-item">
              {/* <span>6.</span> */}
              <form autoComplete="off" onSubmit={___handleFormSubmit}>
                <input
                  className={
                    ___values[5] >= _.max(_.map(___values, _.parseInt))
                      ? 'score-input highest'
                      : 'score-input'
                  }
                  placeholder=""
                  name="5"
                  value={___values[5]}
                  onChange={___handleInputChange}
                  onBlur={___handleFormSubmit}
                />
              </form>
            </div>
          </div>
        </div>
        </div>
        )
      }
      <div className="back-link-holder"> <Link className="back-link" to="/">
          Back
        </Link></div>
    </Home>
  );
};

export default NewMatchBoardForm;
