import React, { useState, useEffect } from 'react';
import _ from 'lodash';

const NewMatchForm = (props) => {
  const initialFieldValues = {
    pool:'',
    playersCount:'',
    matchType: '',
    matchLevel:'',
    player1: '',
    player2: '',
    player3:'',
    player4:'',
  };
  var [values, setValues] = useState(initialFieldValues);
  var [currentMatchLevels, setCurrentMatchLevels] = useState('');
  var [playerList, setPlayerList] = useState([]);
  useEffect(() => {
    if (props.currentId == '') {
      setValues({ ...initialFieldValues });
    } else {
      setValues({ ...props.matchObjects[props.currentId] });
    }
    if (props.playerObjects !== null) {
      let list = [];
      Object.keys(props.playerObjects).map((id) => {
        list.push({
          id,
          firstName: props.playerObjects[id].firstName,
          lastName: props.playerObjects[id].lastName,
          playerNumber: props.playerObjects[id].playerNumber,
        });
      });
      setPlayerList(list);
    } else {
      setPlayerList([]);
    }
  }, [props.currentId, props.matchObjects]);
  useEffect(()=>{
    if(values.matchType === 'Winner Bracket'){
      setCurrentMatchLevels(props.matchLevels.winner);
    } else if(values.matchType === 'Loser Bracket'){
      setCurrentMatchLevels(props.matchLevels.loser);
    } else {
      setCurrentMatchLevels(props.matchLevels.others);
    }
    if(_.includes(twoPlayersMateches, values.matchType)){
      setValues({...values, playersCount:2});
    } else{
      setValues({...values, playersCount:4});
    }
  },[values.matchType]);
  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSelectChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    props.addOrEdit(values);
  };
  const twoPlayersMateches = ['Quarter Finals', 'Semi Finals', 'Finals'];
  const onlyOneLevelMateches = ['R5','R6','R7','Quarter Finals', 'Semi Finals', 'Finals'];

  return (
    <form autoComplete="off" onSubmit={handleFormSubmit} className="form-wrapper">
      <div className="form-element">
      <div className="mb-3 form-element-item">
        <div className="form-floating">
          <select
            className="form-select"
            id="floatingSelect"
            name="pool"
            value={values.pool}
            onChange={handleSelectChange}
          >
            <option>Select</option>
            {props.pools &&
              props.pools.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                );
              })}
          </select>
          <label>Pool</label>
        </div>
      </div>
      <div className="mb-3 form-element-item">
        <div className="form-floating">
          <select
            className="form-select"
            id="floatingSelect"
            name="matchType"
            value={values.matchType}
            onChange={handleSelectChange}
          >
            <option>Select</option>
            {props.matchTypes &&
              props.matchTypes.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                );
              })}
          </select>
          <label>Match Type</label>
        </div>
      </div>
      </div>
      <div className="form-element">
      <div className="mb-3 form-element-item">
        <div className="form-floating">
          <select
            className="form-select"
            id="floatingSelect"
            name="matchLevel"
            value={values.matchLevel}
            onChange={handleSelectChange}
          >
            <option>Select</option>
            {currentMatchLevels &&
              currentMatchLevels.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                );
              })}
          </select>
          <label>Match Level</label>
        </div>
      </div>
      <div className="mb-3 form-element-item">
        <div className="form-floating">
          <select
            className="form-select"
            id="floatingSelect"
            name="playersCount"
            value={values.playersCount}
            onChange={handleSelectChange}
          >
            <option>Select</option>
            {props.playersCounts &&
              props.playersCounts.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                );
              })}
          </select>
          <label>Number Players</label>
        </div>
      </div>
      </div>
      <div className="form-element">
      <div className="mb-3 form-element-item">
        <div className="form-floating">
          <select
            className="form-select"
            id="floatingSelect"
            name="player1"
            value={values.player1}
            onChange={handleSelectChange}
          >
            <option>Select</option>
            {playerList &&
              playerList.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.firstName} {item.lastName} - {`#`+item.playerNumber}
                  </option>
                );
              })}
          </select>
          <label>Player 1</label>
        </div>
      </div>
      <div className="mb-3 form-element-item">
        <div className="form-floating">
          <select
            className="form-select"
            id="floatingSelect"
            name="player2"
            value={values.player2}
            onChange={handleSelectChange}
          >
            <option>Select</option>
            {playerList &&
              playerList.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                   {item.firstName} {item.lastName} - {`#`+item.playerNumber}
                  </option>
                );
              })}
          </select>
          <label>Player 2</label>
        </div>
      </div>
      </div>
      { values.playersCount == 4 && 
      <div className="form-element">
      <div className="mb-3 form-element-item">
        <div className="form-floating">
          <select
            className="form-select"
            id="floatingSelect"
            name="player3"
            value={values.player3}
            onChange={handleSelectChange}
          >
            <option>Select</option>
            {playerList &&
              playerList.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                   {item.firstName} {item.lastName} - {`#`+item.playerNumber}
                  </option>
                );
              })}
          </select>
          <label>Player 3</label>
        </div>
      </div>
      <div className="mb-3 form-element-item">
        <div className="form-floating">
          <select
            className="form-select"
            id="floatingSelect"
            name="player4"
            value={values.player4}
            onChange={handleSelectChange}
          >
            <option>Select</option>
            {playerList &&
              playerList.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                   {item.firstName} {item.lastName} - {`#`+item.playerNumber}
                  </option>
                );
              })}
          </select>
          <label>Player 4</label>
        </div>
      </div>
      </div>
      }
      <input
        value={props.currentId ? 'Update' : 'Save'}
        type="submit"
        className="btn btn-primary btn-block"
      />
    </form>
  );
};

export default NewMatchForm;
