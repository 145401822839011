import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import firebaseDb from '../firebase';
import { Link } from 'react-router-dom';
const NewMatchBoardPool = (props) => {
  var [playerObjects, setPlayerObjects] = useState({});
  var [scoreCardObjects, setScoreCardObject] = useState({});
  useEffect(() => {
    firebaseDb.child('players').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setPlayerObjects({ ...snapshot.val() });
      } else {
        setPlayerObjects({});
      }
    });
    firebaseDb.child('scorecard').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setScoreCardObject({ ...snapshot.val() });
      } else {
        setScoreCardObject({});
      }
    });
  }, []);
  let quarterFinalMatchObject = [],
      semiFinalMatchObject = [],
      finalMatchObject = [],
      winnerMatchObjects = [],
      loserMatchObjects = [],
      round5MatchObjects = [],
      round6MatchObjects = [],
      round7MatchObjects = [];
  const {pool,finalMatch,evenPool} = props;
  Object.keys(pool).map((id) => {
    let obj = {};
    obj.id = id;
    obj.player1 = pool[id].player1;
    obj.player2 = pool[id].player2;
    obj.player3 = pool[id].player3;
    obj.player4 = pool[id].player4;
    obj.matchLevel = pool[id].matchLevel;
    obj.matchType = pool[id].matchType;
    obj.playersCount = pool[id].playersCount;
    if (pool[id].winner) {
      obj.winner = pool[id].winner;
    }
    if (pool[id].winner1 && pool[id].winner2) {
      obj.winner1 = pool[id].winner1;
      obj.winner2 = pool[id].winner2;
    }
    if (pool[id].matchType === "Winner Bracket") {
      winnerMatchObjects.push(obj);
    }
    if (pool[id].matchType === "Loser Bracket") {
      loserMatchObjects.push(obj);
    }
    if (pool[id].matchType === "R5") {
      round5MatchObjects.push(obj);
    }
    if (pool[id].matchType === "R6") {
      round6MatchObjects.push(obj);
    }
    if (pool[id].matchType === "R7") {
      round7MatchObjects.push(obj);
    }
    if (pool[id].matchType === "Quarter Finals") {
      quarterFinalMatchObject.push(obj);
    }
    if (pool[id].matchType === "Semi Finals") {
      semiFinalMatchObject.push(obj);
    }
  });
  if(finalMatch){
    Object.keys(finalMatch).map((id) => {
      let obj = {};
      obj.id = id;
      obj.player1 = finalMatch[id].player1;
      obj.player2 = finalMatch[id].player2;
      obj.player3 = finalMatch[id].player3;
      obj.player4 = finalMatch[id].player4;
      obj.matchLevel = finalMatch[id].matchLevel;
      obj.matchType = finalMatch[id].matchType;
      obj.playersCount = finalMatch[id].playersCount;
      if (finalMatch[id].winner) {
      obj.winner = finalMatch[id].winner;
      }
      if (finalMatch[id].matchType === "Finals") {
        finalMatchObject.push(obj);
      }
    });
  }
  // if (finalMatchObject.length) {
  //   finalMatchObject = [finalMatchObject[0]];
  // }
  console.log(semiFinalMatchObject)
  const fetchScore = (matchId, playerId) =>{
    let score = [];
    _.map(scoreCardObjects, function(value, key) {
      if(value.matchId === matchId && value.playerId === playerId){
        score=value.score;
      }
    });
    return score;
  }
  return (
    <div className="match" className={
      evenPool ? 'match even-pool' : 'match'
    }>
      <div className="match-board">
        {winnerMatchObjects.length > 0 && 
        <div className="match-board-section winner-level-1">
          {_.map(winnerMatchObjects, (item) => {
            if(item.matchLevel === '1'){
                return (
                    <div className={
                        item.playersCount == 4 ? 'player-holder players-4' : 'player-holder players-2'
                      } key={item.id}>
                      <Link
                        to={item.player1 && item.player2 ?`/scorecard/${item.id}/1`:''}
                        className={
                          item.winner === item.player1 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item?.player1]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item?.player1]?.firstName}</div>
                          <div>{playerObjects[item?.player1]?.lastName}</div>
                        </div>
                        <div className="player-score">
                          {_.max(_.map(fetchScore(item?.id,item?.player1), _.parseInt))}
                        </div>
                      </Link>
                      <Link
                        to={item.player1 && item.player2 ? `/scorecard/${item.id}/2`:''}
                        className={
                          item.winner === item.player2 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player2]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player2]?.firstName}</div>
                          <div>{playerObjects[item.player2]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item.id,item.player2), _.parseInt))}
                        </div>
                      </Link>
                      <Link
                        to={item.player1 && item.player2 ? `/scorecard/${item.id}/3`:''}
                        className={
                          item.winner === item.player3 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player3]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player3]?.firstName}</div>
                          <div>{playerObjects[item.player3]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item.id,item.player3), _.parseInt))}
                        </div>
                      </Link>
                      <Link
                        to={item.player1 && item.player2 ? `/scorecard/${item.id}/4`:''}
                        className={
                          item.winner === item.player4 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player4]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player4]?.firstName}</div>
                          <div>{playerObjects[item.player4]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item.id,item.player4), _.parseInt))}
                        </div>
                      </Link>
                      <div className="match-arrow"></div>
                    </div>
                  );
            }
            return false;
          })}
        </div>
        }
        {winnerMatchObjects.length > 0 && 
        <div className="match-board-section winner-level-2">
          {_.map(winnerMatchObjects, (item) => {
            if(item.matchLevel === '2'){
                return (
                    <div className={
                        item.playersCount == 4 ? 'player-holder players-4' : 'player-holder players-2'
                      } key={item.id}>
                      <Link 
                        to={item.player1 && item.player2 ? `/scorecard/${item.id}/1` : ''}
                        className={
                          item.winner === item.player1 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player1]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player1]?.firstName}</div>
                          <div>{playerObjects[item.player1]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item.id,item.player1), _.parseInt))}
                        </div>
                      </Link>
                      <Link
                        to={item.player1 && item.player2 ?`/scorecard/${item.id}/2`:''}
                        className={
                          item.winner === item.player2 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player2]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player2]?.firstName}</div>
                          <div>{playerObjects[item.player2]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item.id,item.player2), _.parseInt))}
                        </div>
                      </Link>
                      <Link
                        to={item.player1 && item.player2 ?`/scorecard/${item.id}/3`:''}
                        className={
                          item.winner === item.player2 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player3]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player3]?.firstName}</div>
                          <div>{playerObjects[item.player3]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item.id,item.player3), _.parseInt))}
                        </div>
                      </Link>
                      <Link
                        to={item.player1 && item.player2 ?`/scorecard/${item.id}/4`:''}
                        className={
                          item.winner === item.player2 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player4]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player4]?.firstName}</div>
                          <div>{playerObjects[item.player4]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item.id,item.player4), _.parseInt))}
                        </div>
                      </Link>
                      <div className="match-arrow"></div>
                    </div>
                  );
            }
            return false;
          })}
        </div>
        }
        {round5MatchObjects.length > 0 && 
        <div className="match-board-section r-5">
          {_.map(round5MatchObjects, (item) => {
            if(item.matchLevel === '1'){
              return (
                <div className={
                    item.playersCount == 4 ? 'player-holder players-4' : 'player-holder players-2'
                  } key={item.id}>
                  <Link 
                    to={item.player1 && item.player2 ? `/scorecard/${item.id}/1` : ''}
                    className={
                      item.winner === item.player1 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player1]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player1]?.firstName}</div>
                      <div>{playerObjects[item.player1]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player1), _.parseInt))}
                    </div>
                  </Link>
                  <Link
                    to={item.player1 && item.player2 ?`/scorecard/${item.id}/2`:''}
                    className={
                      item.winner === item.player2 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player2]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player2]?.firstName}</div>
                      <div>{playerObjects[item.player2]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player2), _.parseInt))}
                    </div>
                  </Link>
                  <Link
                    to={item.player1 && item.player2 ?`/scorecard/${item.id}/3`:''}
                    className={
                      item.winner === item.player2 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player3]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player3]?.firstName}</div>
                      <div>{playerObjects[item.player3]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player3), _.parseInt))}
                    </div>
                  </Link>
                  <Link
                    to={item.player1 && item.player2 ?`/scorecard/${item.id}/4`:''}
                    className={
                      item.winner === item.player2 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player4]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player4]?.firstName}</div>
                      <div>{playerObjects[item.player4]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player4), _.parseInt))}
                    </div>
                  </Link>
                  <div className="match-arrow"></div>
                  <div className="match-arrow second"></div>
                </div>
              );
            }
            return false;
          })}
        </div>
        }
        {round6MatchObjects.length > 0 && 
        <div className="match-board-section r-6">
          {_.map(round6MatchObjects, (item) => {
            if(item.matchLevel === '1'){
              return (
                <div className={
                    item.playersCount == 4 ? 'player-holder players-4' : 'player-holder players-2'
                  } key={item.id}>
                  <Link 
                    to={item.player1 && item.player2 ? `/scorecard/${item.id}/1` : ''}
                    className={
                      item.winner === item.player1 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player1]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player1]?.firstName}</div>
                      <div>{playerObjects[item.player1]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player1), _.parseInt))}
                    </div>
                  </Link>
                  <Link
                    to={item.player1 && item.player2 ?`/scorecard/${item.id}/2`:''}
                    className={
                      item.winner === item.player2 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player2]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player2]?.firstName}</div>
                      <div>{playerObjects[item.player2]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player2), _.parseInt))}
                    </div>
                  </Link>
                  <Link
                    to={item.player1 && item.player2 ?`/scorecard/${item.id}/3`:''}
                    className={
                      item.winner === item.player2 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player3]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player3]?.firstName}</div>
                      <div>{playerObjects[item.player3]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player3), _.parseInt))}
                    </div>
                  </Link>
                  <Link
                    to={item.player1 && item.player2 ?`/scorecard/${item.id}/4`:''}
                    className={
                      item.winner === item.player2 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player4]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player4]?.firstName}</div>
                      <div>{playerObjects[item.player4]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player4), _.parseInt))}
                    </div>
                  </Link>
                  <div className="match-arrow"></div>
                  <div className="match-arrow second"></div>
                </div>
              );
            }
            return false;
          })}
        </div>
        }
        {round7MatchObjects.length > 0 && 
        <div className="match-board-section r-7">
          {_.map(round7MatchObjects, (item) => {
            if(item.matchLevel === '1'){
              return (
                <div className={
                    item.playersCount == 4 ? 'player-holder players-4' : 'player-holder players-2'
                  } key={item.id}>
                  <Link 
                    to={item.player1 && item.player2 ? `/scorecard/${item.id}/1` : ''}
                    className={
                      item.winner === item.player1 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player1]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player1]?.firstName}</div>
                      <div>{playerObjects[item.player1]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player1), _.parseInt))}
                    </div>
                  </Link>
                  <Link
                    to={item.player1 && item.player2 ?`/scorecard/${item.id}/2`:''}
                    className={
                      item.winner === item.player2 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player2]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player2]?.firstName}</div>
                      <div>{playerObjects[item.player2]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player2), _.parseInt))}
                    </div>
                  </Link>
                  <Link
                    to={item.player1 && item.player2 ?`/scorecard/${item.id}/3`:''}
                    className={
                      item.winner === item.player2 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player3]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player3]?.firstName}</div>
                      <div>{playerObjects[item.player3]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player3), _.parseInt))}
                    </div>
                  </Link>
                  <Link
                    to={item.player1 && item.player2 ?`/scorecard/${item.id}/4`:''}
                    className={
                      item.winner === item.player2 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player4]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player4]?.firstName}</div>
                      <div>{playerObjects[item.player4]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player4), _.parseInt))}
                    </div>
                  </Link>
                  <div className="match-arrow"></div>
                  <div className="match-arrow second"></div>
                </div>
              );
            }
            return false;
          })}
        </div>
        }
        {quarterFinalMatchObject.length > 0 && 
        <div className="match-board-section quarter-finals">
          {_.map(quarterFinalMatchObject, (item) => {
            if(item.matchLevel === '1'){
                return (
                    <div className={
                        parseInt(item.playersCount) === 4 ? 'player-holder players-4' : 'player-holder players-2'
                      } key={item.id}>
                      <Link
                        to={item.player1 && item.player2 ? `/scorecard/${item.id}/1`:''}
                        className={
                          item.winner === item.player1 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player1]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player1]?.firstName}</div>
                          <div>{playerObjects[item.player1]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item.id,item.player1), _.parseInt))}
                        </div>
                      </Link>
                      <Link
                        to={item.player1 && item.player2 ? `/scorecard/${item?.id}/2`:''}
                        className={
                          item.winner === item?.player2 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player2]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player2]?.firstName}</div>
                          <div>{playerObjects[item.player2]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item?.id,item.player2), _.parseInt))}
                        </div>
                      </Link>
                      <div className="match-arrow"></div>
                    </div>
                  );
            }
            return false;
          })}
        </div>
        }
        {semiFinalMatchObject.length > 0 && 
        <div className={
          props.top === 1 ? 'match-board-section semi-finals top' : 'match-board-section semi-finals bottom'
        }>
          {_.map(semiFinalMatchObject, (item) => {
            if(item.matchLevel === '1'){
                return (
                    <div className={
                        parseInt(item.playersCount) === 4 ? 'player-holder players-4' : 'player-holder players-2'
                      } key={item.id}>
                      <Link
                        to={item.player1 && item.player2 ? `/scorecard/${item.id}/1`:''}
                        className={
                          item.winner === item.player1 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player1]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player1]?.firstName}</div>
                          <div>{playerObjects[item.player1]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item.id,item.player1), _.parseInt))}
                        </div>
                      </Link>
                      <Link
                        to={item.player1 && item.player2 ? `/scorecard/${item?.id}/2`:''}
                        className={
                          item.winner === item?.player2 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player2]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player2]?.firstName}</div>
                          <div>{playerObjects[item.player2]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item?.id,item.player2), _.parseInt))}
                        </div>
                      </Link>
                      <div className="match-arrow"></div>
                    </div>
                  );
            }
            return false;
          })}
        </div>
        }
        {finalMatchObject.length > 0 && 
        <div className='match-board-section finals top'>
          {_.map(finalMatchObject, (item) => {
            if(item.matchLevel === '1'){
                return (
                    <div className={
                        parseInt(item.playersCount) === 4 ? 'player-holder players-4' : 'player-holder players-2'
                      } key={item.id}>
                      <Link
                        to={item.player1 && item.player2 ? `/scorecard/${item.id}/1`:''}
                        className={
                          item.winner === item.player1 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player1]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player1]?.firstName}</div>
                          <div>{playerObjects[item.player1]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item.id,item.player1), _.parseInt))}
                        </div>
                      </Link>
                      <Link
                        to={item.player1 && item.player2 ? `/scorecard/${item?.id}/2`:''}
                        className={
                          item.winner === item?.player2 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player2]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player2]?.firstName}</div>
                          <div>{playerObjects[item.player2]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item?.id,item.player2), _.parseInt))}
                        </div>
                      </Link>
                      <div className="match-arrow"></div>
                      <div className="player title-winner">
                        <div className="player-name">
                          <div>{playerObjects[item.winner]?.firstName}</div>
                          <div>{playerObjects[item.winner]?.lastName}</div>
                        </div>
                      </div>
                    </div>
                  );
            }
            return false;
          })}
        </div>
        }
      </div>
      <div className="match-board">
      {loserMatchObjects.length > 0 && 
        <div className="match-board-section loser-level-3">
          {_.map(loserMatchObjects, (item) => {
            if(item.matchLevel === '3'){
              return (
                <div className={
                    parseInt(item.playersCount) === 4 ? 'player-holder players-4' : 'player-holder players-2'
                  } key={item.id}>
                  <Link
                    to={item.player1 && item.player2 ? `/scorecard/${item.id}/1`:''}
                    className={
                      item.winner === item.player1 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player1]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player1]?.firstName}</div>
                      <div>{playerObjects[item.player1]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player1), _.parseInt))}
                    </div>
                  </Link>
                  <Link
                    to={item.player1 && item.player2 ? `/scorecard/${item.id}/2`:''}
                    className={
                      item.winner === item.player2 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player2]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player2]?.firstName}</div>
                      <div>{playerObjects[item.player2]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player2), _.parseInt))}
                    </div>
                  </Link>
                  <Link
                    to={item.player1 && item.player2 ? `/scorecard/${item.id}/3`:''}
                    className={
                      item.winner === item.player2 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player3]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player3]?.firstName}</div>
                      <div>{playerObjects[item.player3]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player3), _.parseInt))}
                    </div>
                  </Link>
                  <Link
                    to={item.player1 && item.player2 ? `/scorecard/${item.id}/4`:''}
                    className={
                      item.winner === item.player2 ? 'player winner' : 'player'
                    }
                  >
                    <div className="player-number">
                      {playerObjects[item.player4]?.playerNumber}
                    </div>
                    <div className="player-name">
                      <div>{playerObjects[item.player4]?.firstName}</div>
                      <div>{playerObjects[item.player4]?.lastName}</div>
                    </div>
                    <div className="player-score">
                    {_.max(_.map(fetchScore(item.id,item.player4), _.parseInt))}
                    </div>
                  </Link>
                  <div className="match-arrow"></div>
                </div>
              );
            }
            return false;
          })}
        </div>
      }
      {loserMatchObjects.length > 0 && 
        <div className="match-board-section loser-level-4">
          {_.map(loserMatchObjects, (item) => {
            if(item.matchLevel === '4'){
                return (
                    <div className={
                        parseInt(item.playersCount) === 4 ? 'player-holder players-4' : 'player-holder players-2'
                      } key={item.id}>
                      <Link
                        to={item.player1 && item.player2 ? `/scorecard/${item.id}/1`:''}
                        className={
                          item.winner === item.player1 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player1]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player1]?.firstName}</div>
                          <div>{playerObjects[item.player1]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item.id,item.player1), _.parseInt))}
                        </div>
                      </Link>
                      <Link
                        to={item.player1 && item.player2 ? `/scorecard/${item.id}/2`:''}
                        className={
                          item.winner === item.player2 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player2]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player2]?.firstName}</div>
                          <div>{playerObjects[item.player2]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item.id,item.player2), _.parseInt))}
                        </div>
                      </Link>
                      <Link
                        to={item.player1 && item.player2 ? `/scorecard/${item.id}/3`:''}
                        className={
                          item.winner === item.player2 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player3]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player3]?.firstName}</div>
                          <div>{playerObjects[item.player3]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item.id,item.player3), _.parseInt))}
                        </div>
                      </Link>
                      <Link
                        to={item.player1 && item.player2 ? `/scorecard/${item.id}/4`:''}
                        className={
                          item.winner === item.player2 ? 'player winner' : 'player'
                        }
                      >
                        <div className="player-number">
                          {playerObjects[item.player4]?.playerNumber}
                        </div>
                        <div className="player-name">
                          <div>{playerObjects[item.player4]?.firstName}</div>
                          <div>{playerObjects[item.player4]?.lastName}</div>
                        </div>
                        <div className="player-score">
                        {_.max(_.map(fetchScore(item.id,item.player4), _.parseInt))}
                        </div>
                      </Link>
                      <div className="match-arrow"></div>
                    </div>
                  );
            }
            return false;
          })}
        </div>
        }
      </div>
    </div>
  );
};

export default NewMatchBoardPool;
