import firebase from 'firebase';



//var firebaseConfig = {
   // apiKey: "AIzaSyBxZAdYAACaoJQSJ08uYoxvyG_1fZBnAw8",
    //authDomain: "ld4h-dev.firebaseapp.com",
    //projectId: "ld4h-dev",
    //storageBucket: "ld4h-dev.appspot.com",
    //messagingSenderId: "17236585721",
  //  appId: "1:17236585721:web:9c7ce05b674b00ab7c4628"
//};

//progton config
const firebaseConfig = {
  apiKey: "AIzaSyBmjzQXXZg5XI0Dg_8A9de0TDJG84SnQIk",
  authDomain: "winningticket-7b4c4.firebaseapp.com",
  projectId: "winningticket-7b4c4",
  storageBucket: "winningticket-7b4c4.appspot.com",
  messagingSenderId: "949331008523",
  appId: "1:949331008523:web:7cf06264070499df65fb0e"
};


// Initialize Firebase
var fireDb = firebase.initializeApp(firebaseConfig);
export default fireDb.database().ref();
