import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import NewMatchForm from './NewMatchForm';
import firebaseDb from '../firebase';
import Home from './Home';

const NewMatches = () => {
   const pools = [
    {
        id: '1',
        name:'1'
    },
    {
        id: '2',
        name:'2'
    },
    {
        id: '3',
        name:'3'
    },
    {
        id: '4',
        name:'4'
    },
  ];
  const playersCounts = [
    {
        id: '2',
        name:'2'
    },
    {
        id: '4',
        name:'4'
    },
  ];
  const matchTypes = [
    {
      id: 'Winner Bracket',
      name: 'Winner Bracket',
    },
    {
      id: 'Loser Bracket',
      name: 'Loser Bracket',
    },
    {
      id: 'R5',
      name: 'R5',
    },
    {
      id: 'R6',
      name: 'R6',
    },
    {
      id: 'R7',
      name: 'R7',
    },
    {
      id: 'Quarter Finals',
      name: 'Quarter Finals',
    },
    {
      id: 'Semi Finals',
      name: 'Semi Finals',
    },
    {
      id: 'Finals',
      name: 'Finals',
    }
  ];
  const matchLevels = {
    winner: [{
      id: '1',
      name:'1'
    },
    {
      id: '2',
      name:'2'
    },
  ],
  loser:[{
    id: '3',
    name:'3'
  },
  {
    id: '4',
    name:'4'
  },
  ],
  others:[{
    id: '1',
    name:'1'
  },]
};
  const initialFieldValues = {
    pool:'',
    playersCount:'',
    matchType: '',
    matchLevel:'',
  };
  var [values, setValues] = useState(initialFieldValues);
  var [playerObjects, setPlayerObjects] = useState({});
  var [matchObjects, setMatchObject] = useState({});
  var [currentId, setCurrentId] = useState('');
  useEffect(() => {
    firebaseDb.child('players').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setPlayerObjects({ ...snapshot.val() });
      } else {
        setPlayerObjects({});
      }
    });
  }, []);
  useEffect(() => {
    firebaseDb.child('matches').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setMatchObject({ ...snapshot.val() });
      } else {
        setMatchObject({});
      }
    });
  }, []);
  const addOrEdit = (obj) => {
    if (currentId) {
      firebaseDb.child(`matches/${currentId}`).set(obj, (err) => {
        if (err) {
          console.log(err);
        } else {
          setCurrentId('');
        }
      });
    } else {
      firebaseDb.child('matches').push(obj, (err) => {
        if (err) {
          console.log(err);
        } else {
          setCurrentId('');
        }
      });
    }
  };
  const onDelete = (key) => {
    if (window.confirm('Are you sure to delete this match')) {
      firebaseDb.child(`matches/${key}`).remove((err) => {
        if (err) {
          console.log(err);
        } else {
          setCurrentId('');
        }
      });
    }
  };
  const handleSelectChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const renderWinners = (winner1,winner2) => {
    return (
      <React.Fragment>
      <div>{winner1}</div><div>{winner2}</div>
      </React.Fragment>
    );
  }
  let mappedMatchObjects = [];
  Object.keys(matchObjects).map((id) => {
    let obj = {};
    obj.id = id;
    obj.pool = matchObjects[id].pool;
    obj.player1 = matchObjects[id].player1;
    obj.player2 = matchObjects[id].player2;
    obj.player3 = matchObjects[id].player3;
    obj.player4 = matchObjects[id].player4;
    obj.matchLevel = matchObjects[id].matchLevel;
    obj.matchType = matchObjects[id].matchType;
    obj.playersCount = matchObjects[id].playersCount;
    if (matchObjects[id].winner) {
      obj.winner = matchObjects[id].winner;
    }
    if (matchObjects[id].winner1 && matchObjects[id].winner2) {
      obj.winner1 = matchObjects[id].winner1;
      obj.winner2 = matchObjects[id].winner2;
    }
    mappedMatchObjects.push(obj);
  });
  if(values.pool !== 'Select' && values.pool !== ''){
    mappedMatchObjects = _.filter(mappedMatchObjects, function(o){ return o.pool==values?.pool});
  }
  if(values.matchType !== 'Select' && values.matchType !== ''){
    mappedMatchObjects = _.filter(mappedMatchObjects, function(o){ return o.matchType==values?.matchType});
  }
  return (
    <React.Fragment>
      <Home>
        <div className="filter">
          <div className="filter-heading">
            Filters
          </div>
          <div className="">
            <div className="mb-3">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="floatingSelect"
                  name="pool"
                  value={values.pool}
                  onChange={handleSelectChange}
                >
                  <option>Select</option>
                  {pools &&
                    pools.map((item) => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
                <label>Pool</label>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="floatingSelect"
                  name="matchType"
                  value={values.matchType}
                  onChange={handleSelectChange}
                >
                  <option>Select</option>
                  {matchTypes &&
                    matchTypes.map((item) => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
                <label>Match Type</label>
              </div>
            </div>
          </div>
          
        </div>
        <div className="wrapper">
          <h5 className="m-t-15 text-center f24">Add Match</h5>
          <div className="row">
            <div className="col-xs-12 m-b-30">
              <NewMatchForm
                {...{
                  addOrEdit,
                  currentId,
                  playerObjects,
                  matchObjects,
                  matchTypes,
                  matchLevels,
                  playersCounts,
                  pools
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 m-t-30">
              {Object.keys(matchObjects).length > 0 && <h5>List of Matches</h5>}
            </div>
          </div>
          {Object.keys(matchObjects).length > 0 && (
            <div className="row">
              <div className=" col-xs-12">
                <table className="table table-stripped">
                  <thead className="thead-light">
                    <tr>
                      <th>Players</th>
                      <th>Pool</th>
                      <th>Match Type</th>
                      <th>Match Level</th>
                      <th>Players Count</th>
                      <th>Winner(s)</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(mappedMatchObjects,(item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <div>
                            {
                              playerObjects[`${item.player1}`]
                                ?.firstName
                            } {' '}{
                              playerObjects[`${item.player1}`]
                                ?.lastName
                            }
                            </div>
                            <div>
                            {
                              playerObjects[`${item.player2}`]
                                ?.firstName
                              } {' '}{
                              playerObjects[`${item.player2}`]
                                ?.lastName
                            }
                            </div>
                            <div>
                            {
                              playerObjects[`${item.player3}`]
                                ?.firstName
                              } {' '}{
                              playerObjects[`${item.player3}`]
                                ?.lastName
                            }
                            </div>
                            <div>
                            {
                              playerObjects[`${item.player4}`]
                                ?.firstName
                              } {' '}{
                              playerObjects[`${item.player4}`]
                                ?.lastName
                            }
                            </div>
                          </td>
                          <td>{item.pool}</td>
                          <td>{item.matchType}</td>
                          <td>{item.matchLevel}</td>
                          <td>{item.playersCount}</td>
                          <td>
                            {
                              parseInt(item.playersCount) === 4 ? (item.winner1 && item.winner2
                                ? (renderWinners(playerObjects[`${item.winner1}`]
                                .firstName +' '+ playerObjects[`${item.winner1}`]
                                .lastName,playerObjects[`${item.winner2}`]
                                .firstName +' '+ playerObjects[`${item.winner2}`]
                                .lastName))
                                : '-') : (item.winner
                                ? playerObjects[`${item.winner}`]
                                    .firstName +' '+ playerObjects[`${item.winner}`]
                                    .lastName
                                : '-')
                            }
                          </td>
                          <td>
                            <a
                              className="btn text-primary"
                              onClick={() => {
                                setCurrentId(item.id);
                              }}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </a>
                            <a
                              className="btn text-danger"
                              onClick={() => {
                                onDelete(item.id);
                              }}
                            >
                              <i className="far fa-trash-alt"></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </Home>
    </React.Fragment>
  );
};

export default NewMatches;
