import React, { useState, useEffect } from 'react';
import Home from './Home';
import logo from './../images/ld4h-logo.png';
import _ from 'lodash';
import firebaseDb from '../firebase';
import NewMatchBoardPool from './NewMatchBoardPool';
const NewMatchBoard = () => {
  var [matchObjects, setMatchObject] = useState({});
  const [slide, setSlide] = useState(1);
  useEffect(() => {
    firebaseDb.child('matches').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        setMatchObject({ ...snapshot.val() });
      } else {
        setMatchObject({});
      }
    });
  }, []);
  useEffect(() => {
    const timer = setInterval(() => {
      setSlide(slide === 1 ? 2 : 1);
    }, 120000);
    return () => clearInterval(timer);
  });
  const switchSlide = () =>{
    setSlide(slide === 1 ? 2 : 1);
  }

  let pool1 = {}, pool2 = {},pool3 = {},pool4 = {};
  let finalMatch = {};
  _.forEach(matchObjects, function(value, key) {
    if(value.matchType === 'Finals'){
      finalMatch[key] = value;
    }
    if(parseInt(value.pool)===1){
        pool1[key] = value;
    } else if(parseInt(value.pool)===2){
        pool2[key] = value;
    } else if(parseInt(value.pool)===3){
        pool3[key] = value;
    } else {
        pool4[key] = value;
    }
  });
  const roundHeadings = [
    {
      top: 'R1',
      bottom: 'Open Round'
    },
    {
        top: 'R2',
        bottom: 'Winners R1'
    },
    {
        top: 'R3',
        bottom: 'Losers R1'
    },
    {
        top: 'R4',
        bottom: 'Losers R2 & Winners R3'
    },
    {
        top: 'R5',
        bottom: 'Winners R2'
    },
    {
        top: 'R6',
        bottom: 'Winners R4'
    },
    {
        top: 'R7',
        bottom: 'Losers R5 & Winners R6'
    },
    {
        top: 'R8',
        bottom: 'Quarter Finals'
    },
    {
        top: 'R9',
        bottom: 'Semi Finals'
    },
    {
        top: 'R10',
        bottom: 'Finals'
    },
];
  return (
    <Home>
      {
        !_.isEmpty(pool1) &&<div className="switch" onClick={switchSlide}>Switch slide to {slide === 1 ? 2 : 1}</div>
      }
      <div className="container text-center logo-holder">
          <img src={logo} alt="app logo" className="logo" />
      </div>
      {matchObjects.length <= 0 && <div>No matches</div>}
      <div className="slide-holder">
        <div className={slide === 1 ? "slide show" : "slide hide"}>
          <NewMatchBoardPool pool={pool1} finalMatch={finalMatch} top={1}/>
          {
            !_.isEmpty(pool1) && (<div className="board-heading">
            {_.map(roundHeadings, (item) => {return(
                <div className="board-heading-item" key={item.top}>
                    <div className="board-heading-item-top">{item.top}</div>
                    <div className="board-heading-item-bottom">{item.bottom}</div>
                </div>
                )})
            }
          </div>)
          }

          <NewMatchBoardPool pool={pool2} evenPool={1}/>
        </div>
        <div className={slide === 2 ? "slide show" : "slide hide"}>
          <NewMatchBoardPool pool={pool3} finalMatch={finalMatch} top={0}/>
          {
            !_.isEmpty(pool3) && (<div className="board-heading">
            {_.map(roundHeadings, (item) => {return(
                <div className="board-heading-item" key={item.top}>
                    <div className="board-heading-item-top">{item.top}</div>
                    <div className="board-heading-item-bottom">{item.bottom}</div>
                </div>
                )})
            }
          </div>)
          }
          <NewMatchBoardPool pool={pool4} evenPool={1}/>
        </div>
      </div>
    </Home>
  );
};

export default NewMatchBoard;
