import './App.css';
import './styles/index.scss';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Players from './components/Players';
//import Matches from './components/Matches';
import NewMatches from './components/NewMatches';
import NewMatchBoard from './components/NewMatchBoard';
import NewMatchBoardForm from './components/NewMatchBoardForm';

function App() {
  return (
    <div className="container-fluid app">
      <div className="row">
        <Router>
          <Switch>
            <Route path="/players">
              <Players />
            </Route>
            <Route path="/matches">
              <NewMatches />
            </Route>
            <Route path="/match-board">
              <NewMatchBoard />
            </Route>
            <Route path="/" exact>
              <NewMatchBoard />
            </Route>
            <Route path="/scorecard/:matchId/:playerId" exact>
              <NewMatchBoardForm />
            </Route>
            {/* <Route path="/teams" exact>
              <Teams />
            </Route>
            <Route path="/team-play-matches" exact>
              <TeamPlayMatches />
            </Route>
            <Route path="/team-play" exact>
              <TeamPlayMatchBoard />
            </Route> */}
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
