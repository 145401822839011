import React from 'react';
import { Link } from 'react-router-dom';


const Home = ({ heading, children }) => {
  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          {/* <Link className="navbar-brand" to="/">
            <img src={logo} alt="app logo" className="logo-header" />
          </Link> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  Match Board
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/matches">
                  Matches
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/players">
                  Players
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container home-wrapper">
        {children}
      </div>
    </React.Fragment>
  );
};

export default Home;
