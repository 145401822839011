import React, { useState, useEffect } from 'react';
import {some,isEmpty} from 'lodash';

const PlayerForm = (props) => {
  const initialFieldValues = {
    firstName: '',
    lastName:'',
    playerNumber:'',
  };
  var [values, setValues] = useState(initialFieldValues);
  useEffect(() => {
    if (props.currentId === '') {
      setValues({ ...initialFieldValues });
    } else {
      setValues({ ...props.playerObjects[props.currentId] });
    }
  }, [props.currentId, props.playerObjects]);
  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if(!some(values, isEmpty)){
        props.addOrEdit(values);
    }
  };
  return (
    <form autoComplete="off" onSubmit={handleFormSubmit} className="form-wrapper">
      <div className="mb-3">
      <div className="input-group flex-nowrap mb-4">
        <span className="input-group-text" id="addon-wrapping">Player #</span>
        <input type="number" 
            className="form-control"
            aria-describedby="addon-wrapping"
            placeholder="Enter player number"
            name="playerNumber"
            value={values.playerNumber}
            onChange={handleInputChange}/>
        </div>
        <div className="input-group mb-4">
            <span className="input-group-text">First and last name</span>
            <input className="form-control"
                type="text"
                placeholder="Fisrt Name"
                name="firstName"
                value={values.firstName}
                onChange={handleInputChange}/>
            <input type="text" 
                className="form-control"
                placeholder="Last Name"
                name="lastName"
                value={values.lastName}
                onChange={handleInputChange}/>
        </div>
      </div>
      <div className="mb-3">
        <input
          value={props.currentId ? 'Update' : 'Save'}
          type="submit"
          className="btn btn-primary btn-block"
        />
      </div>
    </form>
  );
};

export default PlayerForm;
